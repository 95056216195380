<template>
  <q-btn
    :color="fillColor"
    flat
    :icon-right="t('icons.arrowDown')"
    :icon="t(`icons.gridSize${12 / gridSize}`)"
    :label="t('toolbar.grid.label')"
    no-caps
    stretch
    @click="onDropdown"
  >
    <q-menu
      auto-close
      class="bg-primary-fade overflow-visible"
      style="width: 136px"
    >
      <blur-mask mask="xy" />
      <q-list bordered class="shadow-24">
        <q-item
          v-for="(option, index) in gridOptions"
          clickable
          :key="`option-${index}-${option.label}`"
          tag="label"
          v-ripple
          @click="gridSize = option.value"
        >
          <q-item-section avatar>
            <q-radio
              v-model="gridSize"
              :val="option.value"
              :color="fillColor"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ option.label }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { computed, inject, watch } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useQuasar } from "quasar";
import { useColorStore } from "src/stores/color";
import { useLayoutStore } from "src/stores/layout";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "ToolbarGridCols" });

const props = defineProps({
  view: { type: String, default: "default" },
});

const bus = inject("bus");
const $q = useQuasar();
const { t } = i18n.global;
const colorStore = useColorStore();
const { fillColor } = storeToRefs(colorStore);
const layoutStore = useLayoutStore();
const { gridSize, gridSizes } = storeToRefs(layoutStore);

const gridOptions = computed(() => gridSizes.value[$q.screen.name]);

const onDropdown = () => {
  bus.emit("showHideSounds");
};

watch(
  gridOptions,
  (options) => {
    gridSize.value = options[1].value;
  },
  { immediate: true }
);
</script>
