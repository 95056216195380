<template>
  <q-btn
    class="stroke-primary"
    :color="fillColor"
    :dense="drawerView"
    flat
    :icon-right="t('icons.arrowDown')"
    :icon="t('icons.scribe')"
    :label="resultSize"
    stretch
    unelevated
    @click="onDropdown"
  >
    <q-menu
      auto-close
      class="bg-primary-fade overflow-visible"
      style="width: 120px"
    >
      <blur-mask mask="xy" />
      <q-list>
        <q-item
          v-for="(option, index) in resultSizeOptions"
          :key="`option-${index}-${option}`"
          tag="label"
          v-ripple
        >
          <q-item-section avatar>
            <q-radio v-model="resultSize" :val="option" :color="fillColor" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ option }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { computed, inject, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";
import { useLayoutStore } from "src/stores/layout";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "ToolbarResultsSize" });
const props = defineProps({
  type: { type: String, default: "collection" },
  view: { type: String, default: "default" },
});

const bus = inject("bus");
const { t } = i18n.global;
const router = useRouter();
const colorStore = useColorStore();
const { fillColor } = storeToRefs(colorStore);
const layoutStore = useLayoutStore();
const { resultSize, resultSizeOptions } = storeToRefs(layoutStore);

const drawerView = computed(() => props.view === "drawer");

const onDropdown = () => {
  bus.emit("showHideSounds");
};

watch(resultSize, (newSize) => {
  const to = {
    name: router.currentRoute.value.name,
    params: { ...router.currentRoute.value.params },
    query: {
      ...router.currentRoute.value.query,
      size: newSize,
    },
  };
  router.push(to);
  bus.emit("showHideSounds");
});
</script>
