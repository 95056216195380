<template>
  <q-btn
    class="stroke-primary"
    :dense="drawerView"
    :icon="t('icons.drawerOpenRightToLeft')"
    no-caps
    no-wrap
    :label="label"
    stretch
    @click="onClick()"
  />
</template>

<script setup>
import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useLayoutStore } from "src/stores/layout";

defineOptions({ name: "ToolbarDrawerToggle" });

const props = defineProps({
  label: { type: String, default: "open" },
  view: { type: String, default: "default" },
});

const bus = inject("bus");
const { t } = i18n.global;
const layoutStore = useLayoutStore();
const { rightDrawerOpen, rightChildDrawerOpen } = storeToRefs(layoutStore);

const drawerView = computed(() => props.view === "drawer");

const onClick = () => {
  !drawerView.value
    ? (rightDrawerOpen.value = !rightDrawerOpen.value)
    : (rightChildDrawerOpen.value = !rightChildDrawerOpen.value);
  bus.emit("showHideSounds");
};
</script>
