<template>
  <q-btn
    :align="drawerView ? 'between' : 'center'"
    :class="{ 'full-width': drawerView }"
    class="stroke-primary"
    :color="fillColor"
    :dense="drawerView"
    flat
    :icon-right="t('icons.arrowDown')"
    :icon="t('icons.arrowUpDown')"
    :label="selectedSortLabel"
    no-caps
    stretch
    @click="onDropdown"
  >
    <q-menu class="bg-primary-fade overflow-visible" style="width: 208px">
      <blur-mask mask="xy" />
      <q-list class="bg-transparent">
        <q-item
          v-for="(option, index) in sortCollectionModel"
          v-ripple
          clickable
          :key="`option-${index}-${option.value}`"
          tag="label"
          @click="onClick()"
        >
          <q-item-section avatar>
            <q-radio
              v-model="selectedSort"
              :color="fillColor"
              :val="option.value"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label caption :class="textColor">{{
              option.label
            }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <q-separator />
      <div class="q-ma-md">
        <q-btn-toggle
          v-model="reverseDirection"
          no-caps
          :options="selectedSortOptions"
          size="sm"
          spread
          :toggle-color="fillColor"
          :toggle-text-color="fillColorInverted"
        />
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { computed, inject, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useColorStore } from "src/stores/color";
import { useSortStore } from "src/stores/sort";
import BlurMask from "src/components/blur/BlurMask.vue";

defineOptions({ name: "ToolbarSortBy" });
const props = defineProps({
  type: { type: String, default: "collection" },
  view: { type: String, default: "default" },
});

const bus = inject("bus");
const { t } = i18n.global;
const router = useRouter();
const colorStore = useColorStore();
const { fillColor, fillColorInverted, textColor, textColorInverted } =
  storeToRefs(colorStore);
const sortStore = useSortStore();
const { reverseDirection, sortCollectionModel, selectedSort } =
  storeToRefs(sortStore);
const selectedSortOptions = computed(() => sortStore.selectedSortOptions);
const selectedSortLabel = computed(() => sortStore.selectedSortLabel);
const combinedValues = computed(() => sortStore.combinedValues);

// const sortOptions = ref(
//   props.type === "search" ? sortSearchModel.value : sortCollectionModel.value
// );

const drawerView = computed(() => props.view === "drawer");

const onClick = (option) => {
  bus.emit("showHideSounds");
};

const onDropdown = () => {
  bus.emit("showHideSounds");
};

const toRoute = () => {
  const to = {
    name: router.currentRoute.value.name,
    params: { ...router.currentRoute.value.params },
    query: {
      ...router.currentRoute.value.query,
      reverse: combinedValues.value.direction,
      sort: combinedValues.value.sort,
    },
  };
  router.push(to);
};

watch(combinedValues, () => {
  toRoute();
});
</script>
