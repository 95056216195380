<template>
  <div
    class="bg-primary-fade overflow-visible relative-position"
    style="z-index: 999"
  >
    <q-separator
      v-if="drawerView || $q.screen.lt.md"
      class="relative-position"
    />
    <q-toolbar
      v-if="(drawerView || $q.screen.lt.md) && type !== 'search'"
      class="header-height justify-center"
    >
      <toolbar-sort-by :type="type" :view="view" />
    </q-toolbar>
    <q-separator class="relative-position" />
    <q-toolbar class="justify-between">
      <q-space v-if="type === 'search'" />
      <toolbar-results-size :type="type" :view="view" />
      <toolbar-sort-by
        v-if="$q.screen.gt.sm && !drawerView && type !== 'search'"
        :type="type"
        :view="view"
      />
      <toolbar-grid-cols v-if="!drawerView" :type="type" :view="view" />
      <toolbar-drawer-toggle
        v-if="type === 'search'"
        :label="t('content.toolbar.drawer.label')"
        :view="view"
      />
      <q-space v-if="type !== 'collection'" />
      <template v-if="(drawerView || $q.screen.lt.sm) && type === 'collection'">
        <q-space />
        <toolbar-drawer-toggle :label="t('filter.label')" :view="view" />
      </template>
    </q-toolbar>
    <q-separator class="relative-position" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { i18n } from "src/boot/i18n";
import ToolbarDrawerToggle from "src/components/toolbar/ToolbarDrawerToggle.vue";
import ToolbarResultsSize from "src/components/toolbar/ToolbarResultsSize.vue";
import ToolbarGridCols from "src/components/toolbar/ToolbarGridCols.vue";
import ToolbarSortBy from "src/components/toolbar/ToolbarSortBy.vue";

defineOptions({ name: "ContentToolbar" });
const props = defineProps({
  type: { type: String, default: "collection" },
  view: { type: String, default: "default" },
});

const { t } = i18n.global;
const drawerView = computed(() => props.view === "drawer");
</script>
