<template>
  <div>
    <content-toolbar
      :type="type"
      :view="view"
      :style="
        type === 'collection' ? 'position: sticky; top: 0; z-index: 1' : void 0
      "
    />
    <div
      class="items-center justify-center q-pa-lg row"
      :class="[`q-col-gutter-${gutter}`]"
    >
      <div
        v-for="(card, index) in content"
        :class="[`col-${colSize}`]"
        :key="`card-${index}`"
      >
        <content-card
          :content="card.node || card"
          :index="index"
          :type="card.node?.__typename || card.__typename"
          :view="view"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useLayoutStore } from "src/stores/layout";
import ContentCard from "./ContentCard.vue";
import ContentToolbar from "./ContentToolbar.vue";

defineOptions({ name: "ContentGrid" });

const props = defineProps({
  col: { type: Number, default: 2 },
  content: { type: Object, default: () => {} },
  gutter: { type: String, default: "sm" },
  useGridSize: { type: Boolean, default: true },
  type: { type: String, default: "collection" },
  view: { type: String, default: "default" },
});

const layoutStore = useLayoutStore();
const { gridSize, gridSizeDrawer } = storeToRefs(layoutStore);

const colSize = computed(() => {
  return props.useGridSize
    ? props.view === "drawer"
      ? gridSizeDrawer.value
      : gridSize.value
    : props.col;
});
</script>
